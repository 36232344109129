import React from "react"
import styled from "styled-components"
import video from "../assets/videos/network.mp4"
import { Button } from "./Buttons"
import emailjs from "emailjs-com"
const Email = () => {
  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_tpxlqx8",
        "template_pk1ixz3",
        e.target,
        "user_qUi6XDBGhTT6MweifSp4I"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
    alert(
      "Thank you for getting in touch with us, we will get back to you as soon as possible!"
    )
  }
  return (
    <EmailContainer id="contact">
      <EmailBg>
        <VideoBg src={video} type="video/mp4" autoPlay loop muted playsInline />
      </EmailBg>
      <EmailContent>
        <h1>Discover Jobills and get Involved</h1>
        <p>Be a part of the better world and connect with us today</p>
        <form action="#" onSubmit={sendEmail}>
          <FormWrap>
            <label htmlFor="email">
              <input
                type="email"
                placeholder="Enter your email"
                id="email"
                name="email"
                required="required"
              />
              <Button
                as="button"
                type="submit"
                round="true"
                css={`
                  height: 48px;

                  @media screen and (max-width: 768px) {
                    width: 100%;
                    min-width: 350px;
                    margin-top: 1rem;
                  }

                  @media screen and (max-width: 400px) {
                    width: 100%;
                    min-width: 250px;
                  }
                `}
              >
                Send
              </Button>
            </label>
          </FormWrap>
        </form>
      </EmailContent>
    </EmailContainer>
  )
}

export default Email

const EmailContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  //   height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  position: relative;
`
const EmailBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;

  overflow: hidden;
`
const VideoBg = styled.video`
  width: 100%;
  height: 450px;
  -o-object-fit: cover;
  object-fit: cover;
`
const EmailContent = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: clamp(1rem, 5vw, 3rem);
    padding: 0 1rem;
  }

  p {
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  form {
    z-index: 10;
  }
`
const FormWrap = styled.div`
  input {
    padding: 1rem 1.5rem;
    outline: none;
    width: 350px;
    height: 48px;
    border-radius: 50px;
    border: none;
    margin-right: 1rem;
    background-color: #0c0c0c;
    color: #c79908;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;

      input {
        margin-bottom: 1rem;
        width: 100%;
        margin-right: 0;
      }
    }
  }
`
