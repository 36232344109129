import React from "react"
import styled from "styled-components"

import { IoMdCheckmarkCircleOutline } from "react-icons/io"
import { FaRegLightbulb } from "react-icons/fa"
import dev1 from "../assets/images/dev1.jpg"
import dev2 from "../assets/images/dev2.jpg"

const DevSect = () => {
  return (
    <DevSectContainer id="devs">
      <TopLine>For Developers</TopLine>
      <Description>Call for Blockchain Developers</Description>
      <ContentWrapper>
        <ColumnOne>
          <Info>
            <FaRegLightbulb
              css={`
                color: #c79908;
                font-size: 2rem;
                margin-bottom: 1rem;
              `}
            />
            <h3>Blockchain at JOBILLS</h3>
            <p>
              The Jobills platform allows developers to take part in exploring
              the blockchain industry through developing various applications on
              top of the Jobills system.Are you a blockchain or full stack
              developer in need of a new challenge and an opportunity to keep
              your skills sharp? Become part of an effort to develop a
              futuristic blockchain system that is both challenging and
              exciting. Jobills (Pty) Ltd is a new South African tech start-up
              that is developing a blockchain system aimed at enhancing human
              communication. As a developer, this is an opportunity to be part
              of a development community that intends to make a contribution to
              modern humanity by improving communication.{" "}
            </p>
          </Info>
          <Info>
            <IoMdCheckmarkCircleOutline
              css={`
                color: #800000;
                font-size: 2rem;
                margin-bottom: 1rem;
              `}
            />
            <h3>Cryptography</h3>
            <p>
              Blockchain is yet to witness its mainstream usage in society, yet
              there are numerous opportunities for professionals to explore and
              develop their careers in this field; one of which is cryptography
              in blockchain for sure. With time this field will be providing
              endless opportunities, and for this, you can join us!{" "}
            </p>
          </Info>
          <Info>
            <IoMdCheckmarkCircleOutline
              css={`
                color: #800000;
                font-size: 2rem;
                margin-bottom: 1rem;
              `}
            />
            <h3>Smart Contracts</h3>
            <p>
              Smart contracts can be used across industries to streamline and
              automate doing business down the street or around the world. We
              intend to utilize their full potential.{" "}
            </p>
          </Info>
          <Info>
            <IoMdCheckmarkCircleOutline
              css={`
                color: #800000;
                font-size: 2rem;
                margin-bottom: 1rem;
              `}
            />
            <h3>Web Development</h3>
            <p>
              In most cases, a blockchain developer will be developing web
              apps.Our vision for blockchain developers is to create apps that
              even people with minimal knowledge of blockchain can easily use.{" "}
            </p>
          </Info>
        </ColumnOne>
        <ColumnTwo>
          <br />
          <Images src={dev1} />
          <Images src={dev2} />
        </ColumnTwo>
      </ContentWrapper>
    </DevSectContainer>
  )
}

export default DevSect

const DevSectContainer = styled.div`
  width: 100%;
  background: #fcfcfc;
  color: #000;
  padding: 5rem calc((100vw - 1300px) / 2);
  height: 100%;
`

const TopLine = styled.p`
  color: #800000;
  font-size: 1.5rem;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
`
const Description = styled.p`
  text-align: start;
  padding-left: 2rem;
  margin-bottom: 4rem;
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-weight: bold;
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const ColumnOne = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`
const Info = styled.div`
  padding-top: 1rem;
  padding-right: 2rem;

  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-style: italic;
  }

  p {
    color: #777777;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
  grid-gap: 10px;

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const Images = styled.img`
  border-radius: 10px;
  height: 500px;
`
// const DevSectContainer= styled.div``
