import * as React from "react"
import About from "../components/About"
import DevSect from "../components/DevSect"
import Email from "../components/Email"
import Hero from "../components/Hero"

import Layout from "../components/layout"
import Mission from "../components/Mission"
import Products from "../components/Products"
import Seo from "../components/seo"
import Vision from "../components/Vision"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <About />
    <Mission />
    <Vision />
    <Products heading="Our Products" />
    <DevSect />
    <Email />
  </Layout>
)

export default IndexPage
