import React from "react"
import styled from "styled-components"

const Vision = () => {
  return (
    <>
      <VisionContainer>
        <VisionTitleWrapper>
          <VisionTitle>Vision</VisionTitle>
        </VisionTitleWrapper>
        <VisionInfo>
          Central to our vision is an important responsibility to improve
          communication through enhancing what natural language can help
          achieve. The Jobills system leverages the advantages of human natural
          languages to develop a simplified communication platform. It is our
          mission to continue developing tools that meet human abilities closer
          to natural capabilities.
        </VisionInfo>
      </VisionContainer>
    </>
  )
}

export default Vision

const VisionContainer = styled.div`
  margin-top: 50px;
  text-align: center;
  padding-right: 35px;
  padding-left: 35px;
  margin-right: auto;
  margin-left: auto;
`

const VisionTitleWrapper = styled.div``
const VisionTitle = styled.h2`
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:after {
    position: absolute;
    content: "";
    background: rgb(199, 153, 8);
    background: linear-gradient(
      90deg,
      rgba(199, 153, 8, 1) 35%,
      rgba(247, 206, 104, 1) 100%
    );
    /* background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
    height: 4px;
    width: 80px;
    bottom: 0;
    margin-left: -42px;
    left: 50%;
  }
`
const VisionInfo = styled.p`
  font-size: 18px;
  color: #777777;
`
