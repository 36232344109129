import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { AiFillSetting } from "react-icons/ai"

const Products = ({ heading }) => {
  const data = useStaticQuery(graphql`
    query productsQuery {
      allProductsJson {
        edges {
          node {
            alt
            title
            info
            img {
              publicURL
            }
          }
        }
      }
    }
  `)

  function getProducts(data) {
    const productsArray = []

    data.allProductsJson.edges.forEach((item, index) => {
      productsArray.push(
        <ProductsCard key={index}>
          <ProductIcon src={item.node.img.publicURL} alt={item.node.alt} />

          <>
            <TextWrap>
              <AiFillSetting
                style={{
                  color: "#0c0c0c",
                  marginBottom: "20px",
                  marginRight: "10px",
                }}
              />
              <ProductsTitle>{item.node.title}</ProductsTitle>
            </TextWrap>
            <ProductInfo>{item.node.info}</ProductInfo>
          </>
        </ProductsCard>
      )
    })
    return productsArray
  }
  return (
    <ProductsContainer id="products">
      <ProductsHeading>
        <ProductHeading>{heading}</ProductHeading>
      </ProductsHeading>
      <ProductsWrapper>{getProducts(data)}</ProductsWrapper>
    </ProductsContainer>
  )
}

export default Products

const ProductsContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  color: #fff;
`
const ProductsHeading = styled.div`
  font-size: clamp(1.2rem, 5vw, 3rem);

  text-align: center;
  margin-bottom: 5rem;
  color: #000;
  position: relative;
`
const ProductHeading = styled.p`
  font-weight: 600;
  padding-bottom: 15px;
  &:after {
    position: absolute;
    content: "";
    background: rgb(199, 153, 8);
    background: linear-gradient(
      90deg,
      rgba(199, 153, 8, 1) 35%,
      rgba(247, 206, 104, 1) 100%
    );
    /* background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
    height: 4px;
    width: 80px;
    bottom: 0;
    margin-left: -42px;
    left: 50%;
  }
`

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`
const ProductsCard = styled.div`
  background-color: #c79908;
  background-image: linear-gradient(62deg, #c79908 0%, #f7ce68 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 380px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`
const ProductIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`
const TextWrap = styled.div`
  display: flex;
  align-items: center;
`
const ProductsTitle = styled.h2`
  font-size: 1rem;
  margin-bottom: 15px;
  font-weight: bold;
  color: #0c0c0c;
`
const ProductInfo = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #0c0c0c;
`
