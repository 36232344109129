import React from "react"
import styled from "styled-components"

const About = () => {
  return (
    <>
      <AboutContainer id="about">
        <AboutTitleWrapper>
          <AboutTitle>About Us</AboutTitle>
        </AboutTitleWrapper>
        <AboutInfo>
          Jobills is a blockchain-based company that uses communication to
          improve social and economic participation for everyone. We focus on
          assisting users become meaningful participants in modern business and
          social communication that is powered by internet technology. The
          Jobills platform makes use of blockchain technology to facilitate a
          shared communication community resource, whereby users can access
          communication tools provided by speakers of different languages. At
          the centre of the Jobills user experience is a community-based system
          that allows users of any level of literacy to be part of a global
          communication process.
        </AboutInfo>
      </AboutContainer>
    </>
  )
}

export default About

const AboutContainer = styled.div`
  margin-top: 50px;
  text-align: center;
  padding-right: 35px;
  padding-left: 35px;
  margin-right: auto;
  margin-left: auto;
`

const AboutTitleWrapper = styled.div``
const AboutTitle = styled.h2`
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:after {
    position: absolute;
    content: "";
    background: rgb(199, 153, 8);
    background: linear-gradient(
      90deg,
      rgba(199, 153, 8, 1) 35%,
      rgba(247, 206, 104, 1) 100%
    );
    /* background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
    height: 4px;
    width: 80px;
    bottom: 0;
    margin-left: -42px;
    left: 50%;
  }
`
const AboutInfo = styled.p`
  font-size: 18px;
  color: #777777;
`
