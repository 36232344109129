import React from "react"
import styled from "styled-components"

const Mission = () => {
  return (
    <>
      <MissionContainer>
        <MissionTitleWrapper>
          <MissionTitle>Mission</MissionTitle>
        </MissionTitleWrapper>
        <MissionInfo>
          Jobills’s mission is to develop a single, decentralised space where
          speakers of any language can interact with those of other languages.
          This will help arrive at a global, social and economic space where
          human progress is powered by the ability to share meaning.
        </MissionInfo>
      </MissionContainer>
    </>
  )
}

export default Mission

const MissionContainer = styled.div`
  margin-top: 50px;
  text-align: center;
  padding-right: 35px;
  padding-left: 35px;
  margin-right: auto;
  margin-left: auto;
`

const MissionTitleWrapper = styled.div``
const MissionTitle = styled.h2`
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:after {
    position: absolute;
    content: "";
    background: rgb(199, 153, 8);
    background: linear-gradient(
      90deg,
      rgba(199, 153, 8, 1) 35%,
      rgba(247, 206, 104, 1) 100%
    );
    /* background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
    height: 4px;
    width: 80px;
    bottom: 0;
    margin-left: -42px;
    left: 50%;
  }
`
const MissionInfo = styled.p`
  font-size: 18px;
  color: #777777;
`
